import React from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { apiHnsn, apiLaureano, apiLaureanoSoul } from '../../services/api';
import { Button } from '@material-ui/core';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import './style.css';
import axios from 'axios';
function LaudoView() {
  const { cdLaudo } = useParams() as any;
  const from = localStorage.getItem('@cedapp:from');
  const { tokenHnsn, tokenLaureano, tokenLaureanoSoul } = useAuth();
  function getTokenLaudo(): string {
    if (from === 'LAUREANO') {
      return `${apiLaureanoSoul.defaults.baseURL}/laudo/android/${tokenLaureanoSoul}/${cdLaudo}`;
    } else if (from === 'LAUREANO_MV2000') {
      return `${apiLaureano.defaults.baseURL}/laudo/android/${tokenLaureano}/${cdLaudo}`;
    } else {
      return `${apiHnsn.defaults.baseURL}/laudo/android/${tokenHnsn}/${cdLaudo}`;
    }
  }

  const isiOS = (): boolean => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  const download = (): void => {
    axios
      .get(getTokenLaudo(), {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      })
      .then(async (response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        await (window.navigator as any).share({
          files: [new File([blob], 'file.pdf', { type: 'application/pdf' })],
        });
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', 'file.pdf'); //or any other extension
        // document.body.appendChild(link);
        // link.click();
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="laudo-view-container">
      <iframe
        src={getTokenLaudo()}
        title="Embedded Content"
        className="iframe"
      />
      {isiOS() && (
        <div className="floating-button-container">
          <Button
            variant="contained"
            color="primary"
            className="floating-button"
            onClick={download}
          >
            <CloudDownloadOutlinedIcon style={{ marginRight: '8px' }} />
          </Button>
        </div>
      )}
    </div>
  );
}

export default LaudoView;
